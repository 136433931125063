import React, { useRef, useEffect, useState } from 'react';
import { Button, Cell, Input, Avatar, Rating } from '@telegram-apps/telegram-ui';
import SwipeableCard from '../SwipeableCard/SwipeableCard';
import styles from './WineRatingSection.module.css';
//import { FaUserTimes } from 'react-icons/fa';

// Define the WineRatingEntry type
type WineRatingEntry = {
  id: number;
  name: string;
  rating: string;
  avatar: string;
};

interface WineRatingItemProps {
  entry: WineRatingEntry;
  editingEntry: { id: number; rating: string } | null;
  colorScheme: string;
  starIcon: React.ComponentType<any>;
  handleEditClick: (entry: WineRatingEntry) => void;
  handleSaveEdit: () => void;
  deleteName: (id: number) => void; // Use id here
  setEditingEntry: React.Dispatch<
    React.SetStateAction<{ id: number; rating: string } | null>
  >;
}

const WineRatingItem: React.FC<WineRatingItemProps> = ({
  entry,
  editingEntry,
  colorScheme,
  starIcon,
  handleEditClick,
  handleSaveEdit,
  deleteName,
  setEditingEntry,
}) => {
  const { id, name, rating, avatar } = entry;
  //const isEditing = editingEntry && editingEntry.id === id;
  const cellRef = useRef<HTMLDivElement>(null);
  const buttonRef = React.useRef<HTMLDivElement>(null);

  const [isNotRated, setIsNotRated] = useState(false);

  if (isNotRated) {
    handleSaveEdit();
    setIsNotRated(false);
  }

  const isEditing = isNotRated ? false : editingEntry && editingEntry.id === id;


  // Use custom hook to detect clicks outside the editing area
  useClickOutside(cellRef, () => {
    if (isEditing) {
      handleSaveEdit();
    }
  });

  // Focus the input when entering edit mode
  useEffect(() => {
    if (isEditing) {
      const input = cellRef.current?.querySelector('input');
      input?.focus();
    }
  }, [isEditing]);

  return (
    <div ref={cellRef}>
    <SwipeableCard
      key={id} // Use id as key
      onDelete={(e) => {
        e.stopPropagation();
        deleteName(id);
      }}
      onClick={() => {handleEditClick(entry); console.log("Card.click");}}
      buttonRef={buttonRef}
      isSwipeDisabled={isEditing ?? false}
    >
      {/* Wrap the Cell in a div and attach the ref */}
      <div 
        style={isEditing ? {
          border: '1px dashed var(--tg-theme-button-color)',
          borderRadius: '5px'} : {}}
      >
        <Cell
          before={isEditing ? null : <Avatar size={40} src={avatar} />}
          after={
            isEditing ? (
              <div style={{zIndex: 10, cursor: 'pointer'}} onClick={() => console.log("Wow")} ref={buttonRef}>
                <Button
                  size="s"
                  mode="bezeled"
                  //onMouseDown={(e) => {
                  //  e.preventDefault();
                  //  e.stopPropagation();
                  //}}
                  onClick={() => {
                    setEditingEntry((prev) =>
                      prev ? { ...prev, rating: '' } : prev
                    ); setIsNotRated(true); console.log("Button.click");}
                  }
                >
                  N/A
                </Button>
              </div>
            ) : <div className={styles.ratingNumber}>{rating}</div>
          }
          subtitle={
            isEditing ? null : (
              <div className={styles.ratingStars} data-theme={colorScheme}>
                <Rating
                  value={rating === 'N/A' ? 0 : parseFloat(rating)}
                  precision={0.1}
                  IconContainer={starIcon}
                />
              </div>
            )
          }
          // onClick={() => handleEditClick(entry)}
        >
          <span style={isEditing ? {paddingLeft: '10px'} : {}}>{name}</span>
          {isEditing && (
            <div className={styles.inputWrapper}>
            <Input
              type="number"
              placeholder="0.0"
              value={editingEntry ? editingEntry.rating : ''}
              onChange={(e) =>
                {setEditingEntry((prev) =>
                  prev ? { ...prev, rating: e.target.value } : prev
                ); console.log("Input.change");}}
              className={styles.ratingInput}
              autoFocus
            />
            </div>
          )}
        </Cell>
      </div>
    </SwipeableCard>
    </div>
  );
};

// Custom hook for detecting clicks outside of a component
function useClickOutside(
  ref: React.RefObject<HTMLElement>,
  handler: () => void
) {
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      if (!ref.current || ref.current.contains(event.target as Node)) {
        return;
      }
      console.log("ClickOutside");
      handler();
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener); // For mobile devices

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
}

export default WineRatingItem;
