import React, { useEffect, useState } from 'react';
import {
  Modal,
  Button,
  Placeholder,
  Section,
  Input,
  List,
} from '@telegram-apps/telegram-ui';
import WineRatingItem from './WineRatingItem'; // Adjust the path as needed
import styles from './WineRatingSection.module.css';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const starIcon = ({ ...props }) => (
  <svg
    width="14"
    height="14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7,0.81l2.05,4.07l4.62,0.65l-3.34,3.17l0.77,4.48L7,11.08L3.97,13.18L4.55,8.72L1.02,5.55l4.6-0.65L7,0.81z"
      fill="currentColor"
      opacity=".8"
    />
  </svg>
);

const parseAndValidateRating = (rating: string, alertFnc: (arg: string) => void) => {
  if (rating.trim() === '') {
    return 'N/A';
  }

  let ratingValue = parseFloat(rating);

  if (rating.length === 2 && !isNaN(ratingValue)) {
    ratingValue /= 10;
  }

  const decimalFormatError = rating.includes('.') && rating.length !== 3;
  if (
    isNaN(ratingValue) ||
    ratingValue < 0 ||
    ratingValue > 5 ||
    decimalFormatError
  ) {
    alertFnc('Оценка должна быть числом между 0.0 и 5.0 с шагом 0.1.');
    return;
  }

  return ratingValue.toFixed(1);
};

type WineRatingEntry = {
  id: number; // Added id field
  name: string;
  rating: string;
  avatar: string;
};

const pickAvatar = (): string => {
  const randomNum = Math.floor(Math.random() * 6) + 1;
  return `./static/media/gachi_avatars/av${randomNum}.jpg`;
};

const PlaceholderImage = ({
  colorScheme,
}: {
  colorScheme: 'dark' | 'light';
}) => (
  <img
    alt="Вино"
    src={`./static/media/wineglass${
      colorScheme === 'dark' ? '-dark' : ''
    }.png`}
    className={styles.image}
  />
);

export const WineRatingSection: React.FC = () => {
  const [names, setNames] = useState<WineRatingEntry[]>([]);
  const [editingEntry, setEditingEntry] = useState<{
    id: number;
    rating: string;
  } | null>(null);
  const [colorScheme, setColorScheme] = useState(
    window.Telegram.WebApp.colorScheme ?? 'light'
  );
  const [nextId, setNextId] = useState(1); // Initialize nextId

  const alertFnc = (text: string) => {
    toast.error(text, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: colorScheme,
      onClose: () => toast.clearWaitingQueue(),
      });
    } 

  useEffect(() => {
    const updateTheme = () =>
      setColorScheme(window.Telegram.WebApp.colorScheme ?? 'light');

    updateTheme();
    window.Telegram.WebApp.onEvent?.('themeChanged', updateTheme);

    return () => {
      window.Telegram.WebApp.offEvent?.('themeChanged', updateTheme);
    };
  }, []);

  const addName = (newEntry: WineRatingEntry) => {
    setNames([...names, newEntry]);
    setNextId(nextId + 1); // Increment nextId
  };

  const updateName = (
    id: number,
    updatedFields: Partial<WineRatingEntry>
  ) => {
    setNames(
      names.map((entry) =>
        entry.id === id ? { ...entry, ...updatedFields } : entry
      )
    );
  };

  const deleteName = (idToDelete: number) =>
    setNames(names.filter((entry) => entry.id !== idToDelete));

  const ModalWithTrigger = () => {
    const [name, setName] = useState('');
    const [rating, setRating] = useState('');

    const handleAddName = () => {
      const trimmedName = name.trim();
      if (!trimmedName) {
        alertFnc('Нужно ввести имя!');
        return;
      }

      // Check for duplicate names (Unique Name Requirement)
      const nameExists = names.some(
        (entry) => entry.name.toLowerCase() === trimmedName.toLowerCase()
      );

      if (nameExists) {
        alertFnc('Такое имя уже есть в списке!');
        return;
      }

      const ratingValue = parseAndValidateRating(rating, alertFnc);
      if (ratingValue === undefined) {
        return;
      }

      const newEntry: WineRatingEntry = {
        id: nextId, // Assign unique id
        name: trimmedName,
        rating: ratingValue,
        avatar: pickAvatar(),
      };

      addName(newEntry);
      setName('');
      setRating('');
    };

    return (
      <Modal
        header={<Modal.Header />}
        trigger={
          <Button size="l" stretched>
            Новый участник
          </Button>
        }
        className={styles.modal}
        nested={true}
      >
        <Placeholder
          description=""
          action={
            <Button onClick={handleAddName} size="m" stretched>
              Добавить
            </Button>
          }
        >
          <div className={styles.modalContent}>
            <Input
              placeholder="Имя"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <Input
              type="number"
              placeholder="Оценка"
              value={rating}
              onChange={(e) => setRating(e.target.value)}
            />
          </div>
        </Placeholder>
      </Modal>
    );
  };

  const handleEditClick = (entry: WineRatingEntry) => {
    if (editingEntry && editingEntry.id === entry.id) {
      // Save edits if the card is already in edit mode
      handleSaveEdit();
    } else {
      if (editingEntry !== null) {
        // Save edits to the previous card
        handleSaveEdit();
      }
      // Now set up editing the new card
      setEditingEntry({
        id: entry.id,
        rating: entry.rating === 'N/A' ? '' : entry.rating ?? '',
      });
    }
  };

  const handleSaveEdit = () => {
    if (editingEntry) {
      const ratingValue = parseAndValidateRating(editingEntry.rating, alertFnc);
      console.log(ratingValue);
      if (ratingValue === undefined) {
        return;
      }
      updateName(editingEntry.id, { rating: ratingValue });
      setEditingEntry(null);
    }
  };

  return (
    <Section header="Оценки по системе Vivino">
      <Placeholder
        header={names.length === 0 ? 'Редактор оценок' : ''}
        description={'Добавляйте имена и назначайте оценки.'}
        action={<ModalWithTrigger />}
        className={styles.section}
      >
        {names.length === 0 && (
          <PlaceholderImage colorScheme={colorScheme ?? 'light'} />
        )}
        <List>
          {names.map((entry) => (
            <WineRatingItem
              key={entry.id} // Use id as key
              entry={entry}
              editingEntry={editingEntry}
              colorScheme={colorScheme}
              starIcon={starIcon}
              handleEditClick={handleEditClick}
              handleSaveEdit={handleSaveEdit}
              deleteName={deleteName}
              setEditingEntry={setEditingEntry}
            />
          ))}
        <ToastContainer limit={1} closeButton={false}/>
        </List>
      </Placeholder>
    </Section>
  );
};

export default WineRatingSection;
